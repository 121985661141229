import React, { useState, useEffect, useRef } from "react";
import "./Timer.css";
import { socket, serverTime, socketConnect } from "../../../Utils/Socket";
import moment from "moment";
import { addTimer } from "../../../Utils/Redux/Store/Actions/timer";
import { connect } from "react-redux";
// import gifImgUrl from "../../../assets/images/timeExtended.gif";
// let serverTime = new Date();

// if (socket) {
//   socket.on("sliservertime", (data) => {
//     if (moment(data.dTime).isValid()) {
//       serverTime = new Date(data.dTime);
//     }
//   });
// }

const getTimeRemaining = (t) => {
  var seconds = ("0" + Math.floor((t / 1000) % 60)).slice(-2);
  var minutes = ("0" + Math.floor((t / 1000 / 60) % 60)).slice(-2);
  var hours = ("0" + Math.floor((t / (1000 * 60 * 60)) % 24)).slice(-2);
  var days = 0;
  if (Math.floor(t / (1000 * 60 * 60 * 24)).toString().length >= 2) {
    days = Math.floor(t / (1000 * 60 * 60 * 24));
  } else {
    days = ("0" + Math.floor(t / (1000 * 60 * 60 * 24))).slice(-2);
  }

  return {
    total: t,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

const mapStateToProps = (state) => {
  return {
    timer: state.Timer.timer || localStorage.getItem("prodtimer"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTimer: (value) => dispatch(addTimer(value)),
  };
};

const TimerComponent = (props) => {
  const [timer, setTimer] = useState({
    timervalid: false,
    timer_id: 0,
    days: 0,
    seconds: 0,
    minutes: 0,
    hours: 0,
  });
  const [isExpired, setIsExpired] = useState(false);

  const [gifKey, setGifKey] = useState(0);
  const [gifUrl, setGifUrl] = useState("/assets/images/timeExtended.gif");
  const gifRef = useRef(null);
  const [isThisWeekDate, setIsThisWeekDate] = useState(false);
  var count = 0;
  const closeDate = new Date(props.date_closed);

  // useEffect(() => {
  //   if (
  //     gifUrl === "/assets/images/timeExtended.gif" &&
  //     // props.bpop_belowfive === 1
  //     timer.seconds > 10
  //   ) {
  //     setGifUrl("/assets/images/timeExtendedAgain.gif");
  //   } else if (
  //     gifUrl === "/assets/images/timeExtendedAgain.gif" &&
  //     // props.bpop_belowfive === 1
  //     timer.seconds <= 10
  //   ) {
  //     setGifUrl("/assets/images/timeExtended.gif");
  //   } else if (
  //     gifUrl === "/assets/images/timeExtendedAgain.gif" &&
  //     // props.bpop_belowfive === 1
  //     timer.seconds > 10
  //   ) {
  //   } else if (
  //     gifUrl === "/assets/images/timeExtended.gif" &&
  //     // props.bpop_belowfive === 1
  //     timer.seconds <= 10
  //   ) {
  //     setGifUrl("");
  //     setGifUrl("/assets/images/timeExtended.gif");
  //   }
  //   setGifKey((prevKey) => prevKey + 1);

  //   console.log("coubt", gifUrl, timer.seconds);
  // }, [props.bpop_next_bid]);

  useEffect(() => {
    if (gifRef.current && props.bpop_belowfive) {
      const src = gifRef.current.src;
      gifRef.current.src = "";
      gifRef.current.src = src;
    }
  }, [props.bpop_belowfive, props.date_closed]);

  useEffect(() => {
    if (
      props.date_closed &&
      moment(props.date_closed).isValid() &&
      props.date_added &&
      moment(props.date_added).isValid()
    ) {
      const interval = setInterval(() => {
        let startDate = new Date(serverTime);
        let endDate = new Date(props.date_closed);
        let addDate = new Date(props.date_added);
        let timerTime = endDate.getTime() - startDate.getTime();
        let future = false;
        let milliSeconds = 0;
        if (addDate > startDate) {
          let incrementedTime = addDate.getTime() + parseInt(milliSeconds);
          timerTime = incrementedTime - startDate.getTime();
          future = true;
        }
        var t = getTimeRemaining(timerTime);
        if (t.total <= 0) {
          if (t.future) {
          } else {
            setIsExpired(true);
            setTimer({
              ...timer,
              timer_id: interval,
              timervalid: false,
            });

            clearInterval(interval);
          }
        } else {
          // setIsExpired(false);
          setTimer({
            ...timer,
            timer_id: interval,
            timervalid: true,
            days: t.days,
            seconds: t.seconds,
            minutes: t.minutes,
            hours: t.hours,
            future: future,
          });
          checkThisWeekDate(props.date_closed);
        }
        localStorage.setItem("prodtimer", future);
        props.changeTimer(future);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    } else if (props.date_closed && moment(props.date_closed).isValid()) {
      const interval = setInterval(() => {
        var startDate = new Date(serverTime);
        var endDate = new Date(props.date_closed);
        var timerTime = endDate.getTime() - startDate.getTime();
        var t = getTimeRemaining(timerTime);
        if (t.total <= 0) {
          setIsExpired(true);
          setTimer({
            ...timer,
            timer_id: interval,
            timervalid: false,
          });

          clearInterval(interval);
        } else {
          // setIsExpired(false);
          setTimer({
            ...timer,
            timer_id: interval,
            timervalid: true,
            days: t.days,
            seconds: t.seconds,
            minutes: t.minutes,
            hours: t.hours,
          });
          checkThisWeekDate(props.date_closed);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [props.date_closed]);

  useEffect(() => {
    if (props.setTimerState) {
      props.setTimerState(props.id, isExpired);
    }
  }, [isExpired]);

  function checkThisWeekDate(futureDate) {
    const now = new Date();
    const sixDaysFifteenHours = 6 * 24 * 60 * 60 * 1000 + 15 * 60 * 60 * 1000;
    const futureTime = new Date(futureDate).getTime();

    const timeDifference = futureTime - now.getTime();

    setIsThisWeekDate(timeDifference <= sixDaysFifteenHours);
  }

  return (
    <div>
      {props.market_status &&
      (props.market_status == "closed" || props.market_status == "sold") ? (
        <div>
          <h2 className="lot_closed">Lot Closed</h2>
        </div>
      ) : timer.timer_id === 0 ? (
        ""
      ) : socketConnect !== 0 ? (
        timer.timervalid && props.bpop_belowfive ? (
          <div
            className={
              isThisWeekDate && timer.timervalid && !timer.future
                ? props.list_view === 1
                  ? "thisWeekTimerList"
                  : "thisWeekTimer"
                : ""
            }
          >
            <small style={{ color: "red" }}>
              {timer.future ? (
                "Starts In :"
              ) : props.bpop_belowfive ? (
                <img
                  ref={gifRef}
                  key={gifKey}
                  src={gifUrl}
                  className="timeExtended timeExtendedGrid"
                  alt="*** TIME EXTENDED ***"
                />
              ) : isThisWeekDate ? (
                <b
                  style={{
                    display: "inline-block",
                    textAlign: "center",
                    fontWeight: "bolder",
                  }}
                >
                  CLOSING SOON - {closeDate.getMonth() + 1}/
                  {closeDate.getDate()}
                </b>
              ) : (
                <b
                  style={{
                    display: "inline-block",
                    textAlign: "center",
                    fontWeight: "bolder",
                  }}
                >
                  CLOSING {closeDate.getMonth() + 1}/{closeDate.getDate()}
                </b>
              )}
            </small>

            {props.onlyGif ? (
              ""
            ) : (
              <div className={`timer ${props.className}`}>
                <div className="ext_bidding">
                  {timer.days} <b style={{ color: "red" }}>DAYS</b>
                </div>
                <div className="ext_bidding">
                  {timer.hours} <b style={{ color: "red" }}>HOUR</b>
                </div>
                <div className="ext_bidding">
                  {timer.minutes} <b style={{ color: "red" }}>MINUTES</b>
                </div>
                <div className="ext_bidding">
                  {timer.seconds} <b style={{ color: "red" }}>SECONDS</b>
                </div>
              </div>
            )}
          </div>
        ) : timer.timervalid ? (
          <div
            className={
              isThisWeekDate && timer.timervalid && !timer.future
                ? props.list_view === 1
                  ? "thisWeekTimerList"
                  : "thisWeekTimer"
                : ""
            }
          >
            {/* <small>
            {timer.future
              ? "Starts In :"
              : props.bpop_belowfive
              ? "*** TIME EXTENDED ***"
              : "Time left :"}
          </small> */}
            {timer.future ? (
              <small>Starts In :</small>
            ) : props.bpop_belowfive ? (
              <img
                ref={gifRef}
                key={gifKey}
                src={gifUrl}
                className="timeExtended timeExtendedGrid"
                alt="*** TIME EXTENDED ***"
              />
            ) : isThisWeekDate ? (
              <b
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  fontWeight: "bolder",
                }}
              >
                CLOSING SOON - {closeDate.getMonth() + 1}/{closeDate.getDate()}
              </b>
            ) : (
              <b
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  fontWeight: "bolder",
                }}
              >
                CLOSING {closeDate.getMonth() + 1}/{closeDate.getDate()}
              </b>
            )}

            {props.onlyGif ? (
              ""
            ) : (
              <div
                className={`timer ${props.className} ${
                  props.bpop_belowfive ? "extendedTimedown" : ""
                }`}
              >
                <div>
                  {timer.days} <b>DAYS</b>
                </div>
                <div>
                  {timer.hours} <b>HOUR</b>
                </div>
                <div>
                  {timer.minutes} <b>MINUTES</b>
                </div>
                <div>
                  {timer.seconds} <b>SECONDS</b>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <h2 className="lot_closed">Lot Closed</h2>
          </div>
        )
      ) : (
        <div>
          <h2 className="socket_disconnect">PLEASE REFRESH YOUR BROWSER</h2>
        </div>
      )}
    </div>
  );
};
const Timer = connect(mapStateToProps, mapDispatchToProps)(TimerComponent);
export default Timer;

import io from "socket.io-client";
import moment from "moment";
import { isAuthenticated } from "../Utils/Auth";
import jwtDecode from "jwt-decode";
const socket = io(process.env.REACT_APP_DOMAIN, { transports: ["websocket"] });
const socket_record = io("https://auction-io.ecommerce.auction", {
  transports: ["websocket"],
});
let serverTime = new Date();
let socketConnect = 1;

const state = {
  socketConnect: "connect",
  listeners: new Set(),
};

const socketState = new Proxy(state, {
  set(target, prop, value) {
    if (prop === "socketConnect" && target[prop] !== value) {
      target[prop] = value;
      target.listeners.forEach((listener) => listener(value));
      return true;
    }
    target[prop] = value;
    return true;
  },
});

function subscribeToSocketConnect(listener) {
  state.listeners.add(listener);
  console.log("subscribeToSocketConnect", listener);
  return () => {
    state.listeners.delete(listener);
  };
}

socket.on("connect", function () {
  console.log("Socket Connected");
});

function bidAddtime(cb) {
  socket.on("bidAddtime", (data) => {
    cb(data);
  });
}

function realClosedUpdates(cb) {
  socket.on("realclosedupdates", (data) => {
    cb(data);
  });
}

function refreshbuyer(cb) {
  socket.on("refreshbuyer", (data) => {
    cb(data);
  });
}
function refreshbuyerOff() {
  socket.off("refreshbuyer");
}
function bid_extended(cb) {
  socket.on("bid_extended", (data) => {
    cb(data);
  });
}
function bidding_flag_update(cb) {
  socket.on("bddw_bidding_flag_update", (data) => {
    cb(data);
  });
}
function bid_extendedOff() {
  socket.off("bid_extended");
}

function updateSocketConnect(data) {
  socketConnect = data;
}

socket.on("sliservertime", (data) => {
  if (moment(data.dTime).isValid()) {
    serverTime = new Date(data.dTime);
  }
  var user = isAuthenticated() ? jwtDecode(isAuthenticated()) : null;
  clearTimeout(socket.pingTimeout);
  socket.pingTimeout = setTimeout(function () {
    console.log("Socket Heartbeat Stop");
    var socket_data = {
      serverTime: serverTime,
      user_id: user ? user.id : 0,
      type: "delay",
    };
    socket_record.emit("bddw_socket_record", socket_data);
    socketConnect = 0;
    if (socketState.socketConnect != "disconnect") {
      socketState.socketConnect = "delay";
    }
  }, 8000);
  return false;
});

socket.on("disconnect", function () {
  console.log("Socket Disconnected");
  var user = isAuthenticated() ? jwtDecode(isAuthenticated()) : null;
  var socket_data = {
    serverTime: serverTime,
    user_id: user ? user.id : 0,
    type: "disconnect",
  };
  socket_record.emit("bddw_socket_record", socket_data);
  socketConnect = 0;
  socketState.socketConnect = "disconnect";
});

socket.on("connect", function () {
  console.log("Socket Connected");
  socketState.socketConnect = "reconnect";
});
export {
  bidAddtime,
  realClosedUpdates,
  refreshbuyer,
  socket,
  refreshbuyerOff,
  bid_extended,
  bid_extendedOff,
  bidding_flag_update,
  serverTime,
  socketConnect,
  updateSocketConnect,
  subscribeToSocketConnect,
};

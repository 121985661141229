import React, { Suspense, useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  withRouter,
  Switch,
  useHistory,
} from "react-router-dom";
import Header from "../components/molecules/Header";
import Landing from "../views/Landing";
import { request } from "../Utils/Request";
import { socket } from "../Utils/Socket";
import jwtDecode from "jwt-decode";
import { isAuthenticated } from "../Utils/Auth";
import { useSnackbar } from "notistack";
import { handleRedirectInternal } from "../Utils";
const Login = React.lazy(() => import("../views/User/Login"));
const Dashboard = React.lazy(() => import("../views/DashBoard"));
const ProductView = React.lazy(() => import("../views/ProductView"));
const Demo = React.lazy(() => import("../views/demo"));
const AdminProductView = React.lazy(
  () => import("../views/ProductView/adminview"),
);
const Search = React.lazy(() => import("../views/Search"));
const ForgotPassword = React.lazy(
  () => import("../views/User/resetpasswordrequest"),
);
const Register = React.lazy(() => import("../views/User/Register"));
const Resetpassword = React.lazy(() => import("../views/User/ForgotPassword"));
const EmailVerification = React.lazy(
  () => import("../views/EmailVerification"),
);
const Static = React.lazy(() => import("../views/StaticPages/static_pages"));
const Adminview = React.lazy(() => import("../views/Admin View/index"));
const InvoiceView = React.lazy(() => import("../views/Invoice/InvoiceView"));
const ErrorPage = React.lazy(() => import("../views/ErrorPage"));
const Checkpassword = React.lazy(() => import("../views/Checkpassword"));

const Routes = (props) => {
  const [initiateroute, setInitiate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const getRoutes = async () => {
    try {
      const res = await request("post", "/get_nav_menu", true);
      global.navigation_details = res.data;
      localStorage.setItem("nav_details", JSON.stringify(res.data));
      setInitiate(true);
    } catch (err) {
      console.log(err, "==> error from nav details");
    }
  };

  useEffect(() => {
    getRoutes();
  }, []);

  useEffect(() => {
    socket.on("user_removed_info", (data) => {
      var user = isAuthenticated() ? jwtDecode(isAuthenticated()) : "";
      if (parseInt(user?.id) == parseInt(data.user_id)) {
        localStorage.removeItem("userToken");
        localStorage.removeItem("Userdetails");
        enqueueSnackbar("YOUR ACCOUNT REMOVED BY ADMIN! ", {
          variant: "error",
          preventDuplicate: true,
        });
        window.location.href = "/login";
      }
    });
    return () => {
      socket.off("user_removed_info", (data) => {
        var user = isAuthenticated() ? jwtDecode(isAuthenticated()) : "";
        if (parseInt(user?.id) == parseInt(data.user_id)) {
          localStorage.removeItem("userToken");
          localStorage.removeItem("Userdetails");
          enqueueSnackbar("YOUR ACCOUNT REMOVED BY ADMIN! ", {
            variant: "error",
            preventDuplicate: true,
          });
          window.location.href = "/login";
        }
      });
    };
  }, []);

  useEffect(() => {
    socket.on("bddw_account_status_change", (data) => {
      console.log(
        "pppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppp",
        data,
      );
      var user = isAuthenticated() ? jwtDecode(isAuthenticated()) : "";
      if (user?.email == data.user_id && data.status == "deactivate") {
        localStorage.removeItem("userToken");
        localStorage.removeItem("Userdetails");
        enqueueSnackbar("YOUR ACCOUNT DEACTIVATED BY ADMIN NOW!", {
          variant: "error",
          preventDuplicate: true,
        });
        window.location.href = "/login";
      }
    });
    return () => {
      socket.off("bddw_account_status_change", (data) => {
        var user = isAuthenticated() ? jwtDecode(isAuthenticated()) : "";
        if (user?.email == data.user_id && data.status == "deactivate") {
          localStorage.removeItem("userToken");
          localStorage.removeItem("Userdetails");
          enqueueSnackbar("YOUR ACCOUNT DEACTIVATED BY ADMIN NOW!", {
            variant: "error",
            preventDuplicate: true,
          });
          window.location.href = "/login";
        }
      });
    };
  }, []);

  return (
    <>
      {initiateroute ? (
        <>
          {!window.location.href.includes("app=1") && <Header />}
          <Suspense fallback={"Loading..."}>
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/landingpage" component={Landing} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/search/:id" component={Search} />
              <Route exact path="/product/:aid/:pid" component={ProductView} />
              <Route
                exact
                path="/productview/:aid/:pid"
                component={AdminProductView}
              />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/dashboard/checkout" component={Dashboard} />
              <Route exact path="/das/:uid/:eid/:rot" component={Adminview} />
              <Route exact path="/verifyuser" component={EmailVerification} />
              <Route
                exact
                path="/verifyMyEmail/:id"
                component={EmailVerification}
              />
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <Route
                exact
                path="/check_auction_password/:slug"
                component={Checkpassword}
              />
              <Route
                exact
                path="/login/forgot_password"
                component={Resetpassword}
              />
              <Route exact path="/static/:id" component={Static} />
              <Route exact path="/:aid/:pid/:type" component={ProductView} />
              <Route exact path="/invoice/:inv_id" component={InvoiceView} />
              <Route exact path="/demo" component={Demo} />
              <Route exact path="*" component={ErrorPage} />
            </Switch>
          </Suspense>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default withRouter(Routes);
